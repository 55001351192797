<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { reqMethods } from '../../../state/helpers';
import AudioPlayer from 'vue3-audio-player'
import 'vue3-audio-player/dist/style.css'
import axios from 'axios';
import Swal from "sweetalert2";


/**
 * Email-read component
 */
export default {
  setup() {

  },
  components: {
    Layout,
    PageHeader,
    AudioPlayer
  },
  data() {

    return {
      title: "Détails de l'alerte",
      items: [
        {
          text: "Alertes",
          href: "/alert/list-alert"
        },
        {
          text: "Détails",
          active: true
        }
      ],
      map: null,
      isLoading: false,
      coversrc: null,
      pertinence: null,
      variant: 'secondary',
      messages: [],
      audios: [],
      videos: [],
      files: [],
      photos: [],
      coordinates: [],
      alert: {},
      updates: {},
      position: {},
      userPosition: {},
      markers: [],
      reqError: null,
      submitted: false,
      form: {
        message: "",
      },
      username: "Steven Franklin",
    };
  },
  created() {
  },
  validations: {
    form: {
      message: {
        required: helpers.withMessage("Message is required", required),
      },
    },
  },
  methods: {
    ...reqMethods,
    getAlertDetails() {
      const that = this;
      that.isLoading = true
      const id = { id: parseInt(this.$route.params.id) };
      this.postRequest({
        route: '/alert/alert-spontaneous/findbyid/',
        data: id,
      })
        .then(
          function (response) {
            that.getAlertUpdates();
            that.alert = response.data;
            console.log('details alertes');
            console.log(that.alert);
            that.pertinence = that.alert.alerts[0].pertinence;
            console.log("perosition 0");
            that.coordinates = that.alert.coordinates;
            that.userPosition = {
              lat: that.alert.alerts[0].sender_current_position.coordinates[0],
              lng: that.alert.alerts[0].sender_current_position.coordinates[1]
            };
            console.log(that.userPosition);
            /*                that.alert.coordinates.forEach((item, index) => {
                              const newPosition = {
                                position: {
                                  lat: item.coordinate.coordinates[0],
                                  lng: item.coordinate.coordinates[1],
                                  title: `Position ${index + 1}`
                                }
                              }
                              console.log(newPosition);
                              that.markers = [...that.markers, newPosition];
                            })*/
            if (that.pertinence == 'PERTINENT') {
              that.variant = 'primary';
            } else if (that.pertinence == 'NON_PERTINENT') {
              that.variant = 'danger';
            } else if (that.pertinence == 'DECISIVE') {
              that.variant = 'success';
            } else if (that.pertinence == 'NORMAL') {
              that.variant = 'secondary';
            }
            that.initMap();
            that.isLoading = false
          },
          function (error) {
            that.reqError = error;
            that.isLoading = false
          }
        )
    },
    CreateCenterControl(map) {
      const controlButton = document.createElement("button");
      controlButton.style.backgroundColor = "#fff";
      controlButton.style.border = "2px solid #fff";
      controlButton.style.borderRadius = "3px";
      controlButton.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
      controlButton.style.color = "rgb(25,25,25)";
      controlButton.style.cursor = "pointer";
      controlButton.style.fontFamily = "Roboto,Arial,sans-serif";
      controlButton.style.fontSize = "16px";
      controlButton.style.lineHeight = "38px";
      controlButton.style.margin = "8px 0 22px";
      controlButton.style.padding = "0 5px";
      controlButton.style.textAlign = "center";

      controlButton.textContent = "Centré la carte";
      controlButton.title = "Click to recenter the map";
      controlButton.type = "button";
      controlButton.addEventListener("click", () => {
        map.setCenter({ lat: this.userPosition.lat, lng: this.userPosition.lng });
      });

      return controlButton;

    },

    initMap() {
      const self = this;
      console.log("init map")

      this.map = new google.maps.Map(this.$refs["map"], {
        zoom: 10,
        center: new google.maps.LatLng(self.userPosition.lat, self.userPosition.lng),
        mapTypeId: google.maps.MapTypeId.ROADMAP
      });

      new google.maps.Marker({
        position: { lat: self.userPosition.lat, lng: self.userPosition.lng },
        map: this.map,
        title: "Position Utilisateur",
        icon: {
          url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
          scaledSize: new google.maps.Size(50, 50),
        }
      });

      this.alert.coordinates.forEach((item, index) => {
        new google.maps.Marker({
          position: { lat: item.coordinate.coordinates[0], lng: item.coordinate.coordinates[1] },
          map: this.map,
          title: `Position ${index + 1}`
        });

      });



      const centerControlDiv = document.createElement("div");
      // Create the control.
      const centerControl = self.CreateCenterControl(this.map);

      // Append the control to the DIV.
      centerControlDiv.appendChild(centerControl);
      this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(centerControlDiv);

      /*this.map.addListener("click", (e) => {
        this.handleMapClick(e)
      });*/
    },

    getAlertUpdates() {
      const that = this;
      const id = parseInt(this.$route.params.id);
      this.getRequest(`/alert/alert-spontaneous/Update/?id=${id}`)
        .then(
          function (response) {
            that.updates = response.data;
            console.log(that.updates);
          },
          function (error) {
            console.log(error);
          }
        )
    },
    async setPertinence(pertinence) {
      const formData = new FormData();
      formData.append('id', parseInt(this.$route.params.id));
      formData.append('pertinence', pertinence);
      try {
        const response = await axios.put(`https://api.alcit.sims-technologie.com/api/v1/alert/alert-spontaneous/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': localStorage.getItem('token'),
          },
        });
        Swal.fire(response.data.message, "L'Alerte à été traité!", "success");
        this.pertinence = pertinence;
        if (pertinence == 'PERTINENT') {
          this.variant = 'primary';
        } else if (pertinence == 'NON_PERTINENT') {
          this.variant = 'danger';
        } else if (pertinence == 'DECISIVE') {
          this.variant = 'success';
        }
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: "Oops...",
          text: "Erreur lors du traitement de l'alerte",
          icon: "error",
          confirmButtonClass: "btn btn-primary w-xs mt-2",
          buttonsStyling: false,
          showCloseButton: true,
        });
      }
    },
    showMarker(coord) {
      this.Markers.push(coord);
    },
    showUserMarker(coord) {
      this.userPosition = coord;
    },
    openPDF(url) {
      window.open(url, '_blank');
    },
    addCenterControl(controlDiv, map) {
      const controlUI = document.createElement('div');

      controlUI.innerHTML = `
        <div style="color: white; background: red; padding: 1rem;">
          You can click me, i can also be a vue component
        </div>
      `;

      controlDiv.appendChild(controlUI);
      controlUI.addEventListener('click', () => {
        // Do what ever you want to happen on click event
        map.setCenter({
          lat: 53.57532,
          lng: 10.01534,
        });
      });
    },

    navigateToNextPage() {
      // Créer le tableau d'objets à passer
      console.log('test navigation');
      const dataToPass = {
        coordinates: this.coordinates ?? [],
        alertObject: this.alert.alerts[0] ?? [],
        alerte: this.alert.alerts[0] ?? []
      };
      console.log(dataToPass);
      localStorage.setItem('myData', JSON.stringify(dataToPass));

      this.$router.push({ name: 'Lancer une Interventions' });
    },

  },
  mounted() {
    this.getAlertDetails();
    this.$nextTick(() => {
      this.coversrc = document.getElementById('imageId').src;

    });
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <img src="@/assets/images/sound.png" id="imageId" class="d-none" alt="">
      <div class="col-12 mb-3">
        <div class="card">
          <div class="row justify-content-end">
            <div class="col-md-6 d-flex">
              <div class="btn-toolbar p-3" role="toolbar">
                <button type="button" class="btn btn-primary me-2" @click="navigateToNextPage()">
                  <i class="mdi mdi-run"></i>
                  Déclencher intervention
                </button>
              </div>
              <b-dropdown split :text="pertinence" :variant="variant" class="p-3">
                <b-dropdown-item @click="setPertinence('PERTINENT')">Pertinent</b-dropdown-item>
                <b-dropdown-item @click="setPertinence('NON_PERTINENT')">Non pertinent</b-dropdown-item>
                <b-dropdown-item @click="setPertinence('DECISIVE')">Décisif</b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
          <div class="card-body">
            <div v-for="(alert0, index) in alert.alerts" :key="index">
              <div class="d-flex mb-4">
                <img class="d-flex me-3 rounded-circle avatar-sm" src="@/assets/images/users/citoyen.png"
                  alt="Generic placeholder image" />
                <div class="flex-grow-1">
                  <h5 class="font-size-13 mt-1">{{ alert0?.sender?.name }}</h5>
                  <small class="text-muted">{{ alert0?.sender?.email }}</small>
                </div>
              </div>
              <div class="row mt-3 justify-content-between">
                <div class="col-md-2 text-primary">
                  <strong class="font-size-13">Téléphone:</strong>
                </div>
                <div class="col-md-10">
                  <h5><strong class="font-size-13">{{ alert0?.sender?.telephone }}</strong></h5>
                </div>
              </div>
              <div class="row mt-3 justify-content-between">
                <div class="col-md-2">
                  <strong class="font-size-13 text-primary">Thématique:</strong>
                </div>
                <div class="col-md-10">
                  <h5><strong class="font-size-13">{{ alert0?.thematic?.name }}</strong></h5>
                </div>
              </div>
              <div class="row mt-3 justify-content-between" v-if="alert0?.title?.length > 0">
                <div class="col-md-2">
                  <strong class="font-size-13 text-primary">Titre:</strong>
                </div>
                <div class="col-md-10">
                  <h5><strong class="font-size-13">{{ alert0?.title }}</strong></h5>
                </div>
              </div>
              <div class="row mt-3 justify-content-between" v-if="alert0?.adresse?.length > 0">
                <div class="col-md-2 text-primary">
                  <strong class="font-size-13">Adresse:</strong>
                </div>
                <div class="col-md-10">
                  <h5><strong class="font-size-13"> {{ alert0?.adresse }} </strong></h5>
                </div>
              </div>
              <div class="row mt-3 justify-content-between">
                <div class="col-md-2 text-primary">
                  <strong class="font-size-13">Date:</strong>
                </div>
                <div class="col-md-10">
                  <h5><strong class="font-size-13">{{ alert0?.created_at?.split('T')[0] }}</strong></h5>
                </div>
              </div>
              <div class="row mt-3 justify-content-between">
                <div class="col-md-2 text-primary">
                  <strong class="font-size-13">heure:</strong>
                </div>
                <div class="col-md-10">
                  <h5><strong class="font-size-13">{{ alert0?.created_at?.split('T')[1]?.split('.')[0] }}</strong></h5>
                </div>
              </div>
              <div v-if="alert?.messages?.length > 0">
                <b-card no-body class="mb-1">
                  <b-card-header v-b-toggle.accordion-0 header-tag="header" role="tab">
                    <h6 class="m-0">
                      <a class="text-dark" href="javascript: void(0);"><strong>Messages </strong></a>
                    </h6>
                  </b-card-header>
                  <b-collapse id="accordion-0" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <ul>
                        <li v-for="(message, index) in alert.messages" :key=index>{{ message?.message }}</li>
                      </ul>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
              <div v-if="alert?.audios?.length > 0">
                <b-card no-body class="mb-1">
                  <b-card-header v-b-toggle.accordion-7 header-tag="header" role="tab">
                    <h6 class="m-0">
                      <a class="text-dark" href="javascript: void(0);"><strong>Audios </strong></a>
                    </h6>
                  </b-card-header>
                  <b-collapse id="accordion-7" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <div class="row">
                        <div class="col-xl-2 col-6" v-for="(audio, index) in alert.audios" :key=index>
                          <div class="card">
                            <AudioPlayer :option="{
                              src: `https://api.alcit.sims-technologie.com${audio.audio}`,
                              title: `Audio-${index + 1}.wav`,
                              coverImage: `${coversrc}`,
                            }" />
                          </div>
                        </div>
                      </div>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
              <div v-if="alert?.photos?.length > 0">
                <b-card no-body class="mb-1">
                  <b-card-header v-b-toggle.accordion-1 header-tag="header" role="tab">
                    <h6 class="m-0">
                      <a class="text-dark" href="javascript: void(0);">
                        <strong>Images </strong>
                      </a>
                    </h6>
                  </b-card-header>
                  <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <div class="row" v-viewer>
                        <div class="col-xl-2 col-6" v-for="(photo, index) in alert.photos" :key=index>
                          <div class="card">
                            <img class="card-img-top img-fluid"
                              :src="`https://api.alcit.sims-technologie.com${photo.image}`" alt="Card image cap" />
                          </div>
                        </div>
                      </div>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
              <div v-if="alert?.videos?.length > 0">
                <b-card no-body class="mb-1">
                  <b-card-header v-b-toggle.accordion-2 header-tag="header" role="tab">
                    <h6 class="m-0">
                      <a class="text-dark" href="javascript: void(0);"><strong>Videos</strong></a>
                    </h6>
                  </b-card-header>
                  <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <div class="row">
                        <div class="col-xl-2 col-6" v-for="(src, index) in alert.videos" :key=index>
                          <div class="card">
                            <video class="card-img-top img-fluid"
                              :src="`https://api.alcit.sims-technologie.com${src.video}`" controls alt="Card image cap" />
                          </div>
                        </div>
                      </div>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
              <div v-if="alert?.files?.length > 0">
                <b-card no-body class="mb-1">
                  <b-card-header v-b-toggle.accordion-2 header-tag="header" role="tab">
                    <h6 class="m-0">
                      <a class="text-dark" href="javascript: void(0);"><strong>Fichers </strong></a>
                    </h6>
                  </b-card-header>
                  <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <div class="row">
                        <div class="col-xl-2 col-6" v-for="(file, index) in alert.files" :key=index>
                          <div class="card text-center">
                            <a @click="openPDF(`https://api.alcit.sims-technologie.com${file.file}`)" href="#">
                              <img class="card-img-top img-fluid" src="@/assets/images/pdf.png" alt="Card image cap" />
                            </a>
                            <strong class="font-size-13 mt-2">File-{{ file.file.split('/media/')[1] }}</strong>
                          </div>
                        </div>
                      </div>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
              <hr />
            </div>
            <!--            <div class="row mt-3 justify-content-between">
                          <div class="col-md-2">
                            <strong class="font-size-13 text-primary">Position zéro:</strong>
                          </div>
                          <div class="col-md-10">
                            <ul>
                              <li>
                                <a href="#" @click="showUserMarker(alert0?.sender_current_position?.coordinates)">Voir position</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div v-if="alert?.coordinates?.length > 0">
                          <hr/>
                          <div class="row mt-3 justify-content-between">
                            <div class="col-md-2">
                              <strong class="font-size-14 text-primary">Positions:</strong>
                            </div>
                            <div class="col-md-10">
                              <ul>
                                <li v-for="(coord, index) in alert.coordinates" :key="index">
                                  <a href="#" @click="showMarker(coord)">Position {{ index + 1 }}</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>-->
            <div class="form-group row mt-3">
              <div class="col-lg-12">
                <section style="height: 700px;" ref="map" class="map"></section>

                <!--                <GMapMap
                    :center=userPosition
                    :zoom=10
                    ref="myMapRef"
                    map-type-id="terrain"
                    style="width: 100%; height: 900px"
                >
                  <GMapMarker
                      :position=userPosition
                      :clickable="true"
                      :draggable="false"
                      :icon='{
          url: "/maps/usermap.svg",
          scaledSize: {width: 95, height: 95},
          labelOrigin: {x: 16, y: -10}
      }'
                  >
                    <GMapInfoWindow
                        :opened="true"
                    >
                      <div>Position de l'utilisateur
                        <MyComponent/>
                      </div>
                    </GMapInfoWindow>
                  </GMapMarker>
                  <GMapMarker
                      :key="index"
                      v-for="(m, index) in markers"
                      :position="m.position"
                      :clickable="true"
                      :draggable="false"
                      :icon='{
          url: "/maps/position.svg",
          scaledSize: {width: 62, height: 62},
          labelOrigin: {x: 16, y: -10}
      }'
                  >
                    <GMapInfoWindow
                        :opened="true"
                    >
                      <div>{{ m.position.title }}
                        <MyComponent/>
                      </div>
                    </GMapInfoWindow>
                  </GMapMarker>
                </GMapMap>-->

              </div>
            </div>
            <!--            <div class="form-group row mt-3">
                          <div class="col-lg-12">
                            <l-map :zoom="zoom" style="height: 500px;" :center="center">
                              <l-tile-layer :url="url" :maxZoom="maxZoom" />
                              <l-marker v-for="( marker, index ) in Markers" :key="index" :visible="true"
                                :lat-lng="marker.coordinate.coordinates">
                                <l-icon :icon-anchor="staticAnchor">
                                  <img src="@/assets/images/leaflet/marker-icon.png" />
                                </l-icon>
                              </l-marker>
                              <l-marker :visible="true" :lat-lng="userPosition" v-if="userPosition?.length > 0">
                                <l-icon :icon-anchor="staticAnchor">
                                  <img src="@/assets/images/leaflet/marker0.png" />
                                </l-icon>
                              </l-marker>
                            </l-map>
                          </div>
                        </div>-->
          </div>
        </div>
      </div>
      <!-- card -->
      <!-- end Col-9 -->
    </div>
  </Layout>
</template>
